import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setProducts, addProduct } from "../redux/slices/productSlice"; // Import actions from the product slice

interface Product {
	id: number;
	name: string;
	code: string;
	product_measurement?: {
		name: string;
	};
	stock: number;
	p_cost: number;
	p_price: number;
	tax?: {
		percent: number;
	};
	images: { image: string }[];
}

const Products: React.FC = () => {
	const [products, setLocalProducts] = useState<Product[]>([]);
	const [loading, setLoading] = useState(true);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [open, setOpen] = useState(false);
	const [newProduct, setNewProduct] = useState<Product>({
		id: 0,
		name: "",
		code: "",
		product_measurement: { name: "" },
		stock: 0,
		p_cost: 0,
		p_price: 0,
		tax: { percent: 0 },
		images: [],
	});
	const dispatch = useDispatch(); // Use dispatch to send actions to Redux

	// Fetch products from the API and store them in Redux
	useEffect(() => {
		const fetchProducts = async () => {
			try {
				const response = await axios.get("/catalog/product/list/");
				setLocalProducts(response.data); // Update local state
				dispatch(setProducts(response.data)); // Save data to Redux
				setLoading(false);
			} catch (error) {
				console.error("Error fetching products:", error);
				setLoading(false);
			}
		};

		fetchProducts();
	}, [dispatch]);

	const columns: GridColDef[] = [
		{
			field: "images",
			headerName: "#",
			width: 60,
			renderCell: (params) => (
				<img
					src={params.value[0]?.image || "path/to/default-image.jpg"}
					alt="product"
					className="w-8 h-8 rounded-md justify-center"
				/>
			),
		},
		{ field: "name", headerName: "Məhsul adı", width: 150 },
		{ field: "code", headerName: "Məhsul kodu", width: 130 },
		{
			field: "product_measurement",
			headerName: "Ölçü vahidi",
			width: 130,
		},
		{ field: "stock", headerName: "Anbar qalığı", width: 130 },
		{ field: "p_cost", headerName: "Maya dəyəri", width: 130 },
		{ field: "p_price", headerName: "Satış qiyməti", width: 130 },
		{
			field: "tax",
			headerName: "ƏDV",
			width: 130,
		},
	];

	// Open the modal dialog
	const handleClickOpen = () => {
		setOpen(true);
	};

	// Close the modal dialog
	const handleClose = () => {
		setOpen(false);
	};

	// Handle input changes in the form
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewProduct({ ...newProduct, [event.target.name]: event.target.value });
	};

	// Handle form submission
	const handleSubmit = async () => {
		try {
			const response = await axios.post("/catalog/product/create/", newProduct);
			dispatch(addProduct(response.data)); // Add new product to Redux
			handleClose();
			// Fetch the updated list of products after adding a new one
			const updatedResponse = await axios.get("/catalog/product/list/");
			setLocalProducts(updatedResponse.data);
		} catch (error) {
			console.error("Error adding product:", error);
		}
	};

	return (
		<div className="">
			{/* Top section with title and buttons */}
			<div className="flex justify-between items-center px-2 py-4 mb-4">
				<div>
					<h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
						Products
					</h1>
				</div>
				<div className="space-x-2">
					<Button variant="outlined" color="primary" onClick={handleClickOpen}>
						Add Product
					</Button>
				</div>
			</div>

			{/* Main section with the products table */}
			<div className="flex flex-col max-h-max max-w-max justify-center rounded-lg shadow-md p-6 dark:border dark:border-gray-800 bg-white dark:bg-zinc-900 ">
				<DataGrid
					rows={products}
					columns={columns}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					disableRowSelectionOnClick
					loading={loading}
					className="bg-transparent"
					sx={{
						"& .MuiDataGrid-columnHeaders": {
							backgroundColor: "rgba(255, 255, 255, 0.1)",
						},
						"& .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						"& .MuiDataGrid-cell": {
							alignContent: "center",
							color: "black",
							"&.Mui-dark": {
								color: "white",
							},
						},
						"& .MuiDataGrid-root": {
							border: "none",
						},
					}}
				/>
			</div>

			{/* Modal window */}
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
				<DialogTitle>Add New Product</DialogTitle>
				<DialogContent>
					<TextField
						margin="dense"
						name="name"
						label="Product Name"
						type="text"
						fullWidth
						variant="outlined"
						value={newProduct.name}
						onChange={handleChange}
					/>
					<TextField
						margin="dense"
						name="code"
						label="Product Code"
						type="text"
						fullWidth
						variant="outlined"
						value={newProduct.code}
						onChange={handleChange}
					/>
					<TextField
						margin="dense"
						name="product_measurement"
						label="Measurement Unit"
						type="text"
						fullWidth
						variant="outlined"
						value={newProduct.product_measurement?.name}
						onChange={(e) =>
							setNewProduct({
								...newProduct,
								product_measurement: { name: e.target.value },
							})
						}
					/>
					<TextField
						margin="dense"
						name="stock"
						label="Stock"
						type="number"
						fullWidth
						variant="outlined"
						value={newProduct.stock}
						onChange={handleChange}
					/>
					<TextField
						margin="dense"
						name="p_cost"
						label="Cost Price"
						type="number"
						fullWidth
						variant="outlined"
						value={newProduct.p_cost}
						onChange={handleChange}
					/>
					<TextField
						margin="dense"
						name="p_price"
						label="Selling Price"
						type="number"
						fullWidth
						variant="outlined"
						value={newProduct.p_price}
						onChange={handleChange}
					/>
					<TextField
						margin="dense"
						name="tax"
						label="VAT"
						type="number"
						fullWidth
						variant="outlined"
						value={newProduct.tax?.percent}
						onChange={(e) =>
							setNewProduct({
								...newProduct,
								tax: { percent: parseFloat(e.target.value) },
							})
						}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary">
						Add
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Products;
