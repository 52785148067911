import axios from "axios";
import Cookies from "js-cookie";
import {
	User,
	TokenObtainPair,
	TokenRefresh,
	AuthToken,
} from "../types/authTypes";

export async function getUserByToken(token: string): Promise<User> {
	try {
		console.log("Sending request with token:", token);

		const response = await axios.get<User>("/user-auth/v1/userdetail/", {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		});

		console.log("Response:", response);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error("Axios error:", error.message);
			console.error("Response:", error.response);
		} else {
			console.error("Unexpected error:", error);
		}
		throw error;
	}
}

export async function login(credentials: TokenObtainPair): Promise<AuthToken> {
	const response = await axios.post<TokenRefresh>(
		"/user-auth/v1/token/",
		credentials,
		{
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			withCredentials: true,
		}
	);
	const auth: AuthToken = {
		token: response.data.access,
		refresh: response.data.refresh,
	};
	setAuthCookies(auth);
	return auth;
}

export async function refreshToken(): Promise<string | null> {
	try {
		const refreshToken = Cookies.get("refresh_token");
		if (!refreshToken) {
			throw new Error("No refresh token found");
		}

		const response = await axios.post<TokenRefresh>(
			"/user-auth/v1/token/refresh/",
			{ refresh: refreshToken },
			{
				headers: {
					"Content-Type": "application/json",
				},
				withCredentials: true,
			}
		);

		const newAccessToken = response.data.access;
		if (newAccessToken) {
			Cookies.set("access_token", newAccessToken, {
				secure: true, // Only send the cookie over HTTPS
				sameSite: "None", // Allows sending cookies in cross-site requests
			});
			return newAccessToken;
		} else {
			throw new Error("Failed to refresh access token");
		}
	} catch (error) {
		console.error("Error refreshing token:", error);
		removeAuthCookies();
		return null;
	}
}

export async function verifyToken(token: string): Promise<void> {
	try {
		await axios.post("/user-auth/v1/token/verify/", { token });
	} catch (error) {
		console.error("Error verifying token:", error);
		throw error;
	}
}

export const setAuthCookies = (auth: AuthToken): void => {
	Cookies.set("access_token", auth.token || "", {
		secure: true, // Only send the cookie over HTTPS
		sameSite: "None", // Allows sending cookies in cross-site requests
	});
	Cookies.set("refresh_token", auth.refresh || "", {
		secure: true, // Only send the cookie over HTTPS
		sameSite: "None", // Allows sending cookies in cross-site requests
	});
};

export const getAuthCookies = (): AuthToken | null => {
	const accessToken = Cookies.get("access_token");
	const refreshToken = Cookies.get("refresh_token");
	if (accessToken && refreshToken) {
		return {
			token: accessToken,
			refresh: refreshToken,
		};
	}
	return null;
};

export const removeAuthCookies = (): void => {
	Cookies.remove("access_token");
	Cookies.remove("refresh_token");
};
