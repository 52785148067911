import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../modules/auth/types/authTypes";

interface AuthState {
	user: User | null;
	token: string | null;
	refreshToken: string | null;
}

const initialState: AuthState = {
	user: null,
	token: null,
	refreshToken: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) => {
			state.user = action.payload;
		},
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		setRefreshToken: (state, action: PayloadAction<string>) => {
			state.refreshToken = action.payload;
		},
		logout: (state) => {
			state.user = null;
			state.token = null;
			state.refreshToken = null;
		},
	},
});

export const { setUser, setToken, setRefreshToken, logout } = authSlice.actions;
export default authSlice.reducer;
