import React from "react";

const LayoutSplashScreen: React.FC = () => {
	return (
		<div className="flex justify-center items-center h-screen" style={{textAlign:"center", marginTop:"300px"}}>
			<h1 className="text-xl font-bold">Yüklənir...</h1>
		</div>
	);
};

export default LayoutSplashScreen;
