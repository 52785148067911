import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import PrivateRoutes from "./routing/PrivateRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { AuthPage } from "./modules/auth/pages/AuthPage";
import Dashboard from "./pages/dashboard/Dashboard";
import Products from "./pages/Products";
import Categories from "./pages/Categories";

const App: React.FC = () => {
	return (
		<Routes>
			{/* Public Routes */}
			<Route path="/login/*" element={<PublicRoutes />}>
				<Route path="*" element={<AuthPage />} />
			</Route>
			{/* Private Routes */}
			<Route path="/app/*" element={<PrivateRoutes />}>
				<Route index path="products" element={<Products />} />
				<Route index path="categories" element={<Categories />} />
				<Route path="dashboard" element={<Dashboard />} />
			</Route>
			{/* Redirect to /app/dashboard by default */}
			<Route path="/" element={<Navigate to="/app/" />} />
		</Routes>
	);
};

export default App;
