import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
} from "@mui/material";
import axios from "axios";
import useAuth from "../modules/auth/hooks/useAuth";
import { useDispatch } from "react-redux";
import { setCategories, addCategory } from "../redux/slices/categorySlice"; // Import actions from the slice

interface Category {
	id: number;
	name: string;
	image?: string;
	organization: number;
	parent?: string;
}

const Categories: React.FC = () => {
	const { auth } = useAuth();
	const dispatch = useDispatch(); // Use dispatch to send actions to Redux
	const [categories, setLocalCategories] = useState<Category[]>([]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [open, setOpen] = useState(false);
	const [newCategory, setNewCategory] = useState<Category>({
		id: 0,
		name: "",
		image: "",
		organization: 2, // Example organization ID
		parent: "",
	});

	// Fetch category data from the API
	const fetchCategories = async () => {
		try {
			const token = auth?.token;
			const response = await axios.get("/catalog/category/list/?org_id=2", {
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			});
			setLocalCategories(response.data); // Update local state
			dispatch(setCategories(response.data)); // Save data to Redux
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	// Fetch categories when the component mounts
	useEffect(() => {
		fetchCategories();
	}, []);

	const columns: GridColDef[] = [
		{
			field: "image",
			headerName: "#",
			width: 60,
			renderCell: (params) => (
				<img
					src={params.value || "default-image.png"}
					alt="category"
					className="w-8 h-8 rounded-md justify-center"
				/>
			),
		},
		{ field: "name", headerName: "Category Name", width: 150 },
		{ field: "organization", headerName: "Organization ID", width: 130 },
		{ field: "parent", headerName: "Parent Category", width: 150 },
	];

	// Open the modal dialog
	const handleClickOpen = () => {
		setOpen(true);
	};

	// Close the modal dialog
	const handleClose = () => {
		setOpen(false);
	};

	// Handle input changes in the form
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewCategory({ ...newCategory, [event.target.name]: event.target.value });
	};

	// Handle form submission
	const handleSubmit = async () => {
		try {
			await axios.post("/catalog/category/create/", newCategory);
			handleClose();
			fetchCategories(); // Update category list after adding a new one
		} catch (error) {
			console.error("Error adding category:", error);
		}
	};

	return (
		<div className="">
			{/* Header section with title and buttons */}
			<div className="flex justify-between items-center px-2 py-4 mb-4">
				<div>
					<h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
						Categories
					</h1>
				</div>
				<div className="space-x-2">
					<Button variant="outlined" color="primary" onClick={handleClickOpen}>
						Add New Category
					</Button>
				</div>
			</div>

			{/* Main section with the category table */}
			<div className="flex flex-col max-h-max max-w-max justify-center rounded-md shadow-sm p-6 dark:border dark:border-gray-800 bg-white dark:bg-zinc-900 ">
				<DataGrid
					rows={categories}
					columns={columns}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					disableRowSelectionOnClick
					className="bg-transparent"
					sx={{
						"& .MuiDataGrid-columnHeaders": {
							backgroundColor: "rgba(255, 255, 255, 0.1)",
						},
						"& .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						"& .MuiDataGrid-cell": {
							alignContent: "center",
							color: "black",
							"&.Mui-dark": {
								color: "white",
							},
						},
						"& .MuiDataGrid-root": {
							border: "none",
						},
					}}
				/>
			</div>

			{/* Modal window */}
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
				<DialogTitle>Add New Category</DialogTitle>
				<DialogContent>
					<TextField
						margin="dense"
						name="name"
						label="Category Name"
						type="text"
						fullWidth
						variant="outlined"
						value={newCategory.name}
						onChange={handleChange}
					/>
					<TextField
						margin="dense"
						name="image"
						label="Image URL (optional)"
						type="text"
						fullWidth
						variant="outlined"
						value={newCategory.image}
						onChange={handleChange}
					/>
					<TextField
						margin="dense"
						name="parent"
						label="Parent Category (optional)"
						type="text"
						fullWidth
						variant="outlined"
						value={newCategory.parent}
						onChange={handleChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary">
						Add
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Categories;
